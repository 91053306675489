@import '~@elastic/eui/dist/eui_theme_amsterdam_light.min.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~antd/dist/antd.css';
@import '~@elastic/charts/dist/theme_light.css';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }
}
